// Color scheme
$button-bg: #250103;
$white: #ffffff;
$light: #e4eef5;
$medium: #9bacb3;
$dark: #43454b;
$altdark: #232629;
$accent: #433230;
$highlight: #e0c8af;
$graphite: #303d46;
$black: #010101;

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  #descriptor {
    .descirptor-bg {
      max-height: 100vh;
      width: 85%;
      z-index: 1;
      left: 35%;
      background: url("./images/bg-descriptor-wide.jpg") no-repeat 35% 70px;
      background-size: contain;
    }

    .aligner {
      bottom: 0;
      height: 100%;
      width: 100%;
      right: 40%;
      z-index: 2;
    }

    h1 {
      font-size: 28px;
      line-height: 34px;
    }
    .description-txt {
      margin: 40px 0;
      font-size: 16px;
      line-height: 28px;
    }
    .description {
      max-width: 45%;
      position: relative;
      // background: radial-gradient(closest-corner at center, #3a3e42 0%, #232629 100%);
      background: transparent;
    }
    .btn {
      max-width: 45%;
      margin-top: 50px;
      margin-left: 0;
    }
  }
  header {
    .container {
      .logo {
        margin-left: auto;
      }
    }
  }

  #services {
    .services-list {
      grid-template-columns: 20% 20% 20% 20% 20%;
      grid-column-gap: 15px;
  }
}
  footer {
    padding: 20px 0;
    font-size: 14px;
    line-height: 19px;
    .container {
      flex-direction: row;
      .policy {
        text-align: left;
        width: 80%;
      }
      p {
        margin: 0;
      }
      .social {
        width: 20%;
        text-align: right;
      }
    }
  }
  #blog {
    .posts-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }
    .single-post {
      max-width: 990px;
      margin: 0 auto;
      .post-text {
        padding: 15px 25px;
      }
    }
  }
  #blog .post .post-heading, #blog .single-post .post-heading {
    h1 {
      font-size: 42px;
      line-height: 1.35;
    }
    h2 {
      font-size: 28px;
    }
  .img-wrapper {
    height: 240px;
    }
  }
  #blog .post-content .post-heading {
    h2 {
      font-size: 28px;
    }
  .img-wrapper {
    height: 340px;
    }
  }

  #contacts.blog-services {
    &:before {
      background-image: url(./images/chetv-call.png);
      background-size: contain;
      background-position: 35% center;
    }
  }
  #service-descriptor {
    .container {
      h1 {
        font-size: 42px;
      }
      p {
        font-size: 18px;
      }
      p.p-cta {
        font-size: 24px;
      }
      .btn {
        max-width: 40%;
        margin: 0 auto;
      }
    }
  }
  #list {
    .container {
      h2 {
        font-size: 40px;
      }
      p {
        &.anounce-list {
          font-size: 26px;
        }
      }
      ul {
        margin: 40px 0;
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          line-height: 1.25;
        }
      }
    }
    &.property {
      ul {
        max-width: 100%;
        li {
          width: 33.3%;
        }
      }
    }
  }
  #text-block {
    .block-content h2 {
      font-size: 40px;
    }
    p {
      font-size: 18px;
    }
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  header {
    .header-contacts {
      display: block;
    }
    .lang-switch {
      margin-right: auto;
    }
    .navbar {
      ul {
        display: flex;
      }
    }
    .mobile-nav-toggle {
      display: none;
    }
  }

  #descriptor {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    .description-txt {
      font-size: 14px;
      line-height: 28px;
    }
    .description {
      max-width: 60%;
    }
    .btn {
      margin-left: 7.5%;
    }
  }

  #results {
    .value {
      margin: 0 auto;
      width: 25%;
      min-width: 270px;
    }
  }
  #testimonials {
    .frame {
      padding: 60px;
      .frame-inside {
        border: 1px solid #e0c8af;
      }
    }
  }

  #contacts {
    .contacts-wrapper {
      flex-direction: row;
      > div {
        width: 50%;
      }
      .location-container {
        .requisite-name {
          display: inline-block;
        }
        .map {
          max-width: 400px;
        }
      }
    }
  }

  #team {
    &:before {
      background-image: url("./images/bg-team.jpg");
    }
    .team-wrapper {
      flex-direction: row;
      justify-content: space-around;
      .member {
        margin: 0 15px;
        width: 33, 33%;
      }
    }
  }
  #algorythm {
    &:before {
      // background-image: url("./images/bg-team1.jpg");
      background-image: none;
    }
    .container {
      position: relative;
    }
    .order-container {
      margin-bottom: 100px;
      &:before {
        display: none;
      }
      .timeline-item {
        max-width: 50%;
        > div {
          height: 180px;
          margin: 20px 0;
        }
        .timeline-item-inside {
          padding: 15px;
          background-color: white;
          max-width: 90%;
          min-height: 160px;
          margin: 0;
        }
        &:nth-child(odd) {
          > div {
            border-right: 3px solid $medium;
            h3:before {
              left: unset;
              right: -21px;
            }
            h3:after {
              border-width: 16px 0 16px 20px;
              border-color: transparent transparent transparent $white;
              left: unset;
              right: 45px;
            }
          }
        }
        &:nth-child(even) {
          top: 100px;
          > div {
            border-left: 3px solid $medium;
            margin-left: -3px;
            h3:before {
              right: unset;
              left: -24px;
            }
            h3:after {
              border-width: 16px 20px 16px 0;
              border-color: transparent $white transparent transparent;
              right: unset;
              left: 41px;
            }
            .timeline-item-inside {
              margin-left: auto;
            }
          }
        }
        &:last-child > div {
          border: none;
          &:before {
            content: "";
            left: -3px;
            position: absolute;
            width: 3px;
            height: 100%;
            background-color: #e4eef5;
          }
        }
        > div {
          span {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
  #profile-page {
    .profile {
      flex-direction: row;
      > div {
        padding: 0 20px;
      }
      .profile-side {
        flex-direction: column;
        .profile-img-wrapper {
          margin-top: -110px;
        }
        .profile-about {
          .profile-about-h {
            text-align: left;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  header {
    .navbar {
      ul {
        li {
          font-size: 14px;
          margin-right: 20px;
        }
      }
    }
  }

  #descriptor {
    min-height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
    font-size: 22px;
    .container {
      width: 100%;
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .description-txt {
      font-size: 16px;
      line-height: 28px;
    }
    .description {
      max-width: 55%;
    }

    .btn {
      max-width: 40%;
    }
  }
}
